.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
  margin-right: 10px;
}

.slick-slide > div {
  height: 100%;
}

.slick-slide > div > div {
  height: 100%;
}

.custom-dots {
  display: flex !important;
  justify-content: center;
  margin-top: 50px;
}

.slick-dots {
  position: relative !important;
  bottom: 0 !important;
  margin-top: 40px !important;
  margin-bottom: 20px !important;

  button {
    display: block;
    width: 1rem;
    height: 1rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: blue;
    text-indent: -9999px;
  }

  li button {
    background-color: #00000080 !important;
  }
  li.slick-active button {
    background-color: #7bbe5a !important;
  }
}
/* 
.slick-slide div div {
  height: 100%;
} */
